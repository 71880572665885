<template>
<div class="content">
  <div class="headline">
    <h1>
      Impressum
    </h1>
  </div>
  <h4>Angaben gem&auml;&szlig; &sect; 5 TMG</h4>
  <p>Harald Bohner<br />
    Musiker, Musikp&auml;dagoge, Dozent<br />
    Tieckstr. 6<br />
    04275 Leipzig</p>

  <h4>Kontakt</h4>
  <p>Telefon: +49 (0) 1637313955<br />
    E-Mail: info@gitarrenunterricht-bohner.de</p>

  <h4>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h4>
  <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

  <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
</div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Impressum",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Impressum';
      }
    },
  }
}
</script>

<style scoped>

a,
a:hover{
  color: black;
}

</style>