<template>
  <div class="content">
<!--    <form action="../../contact.php" method="post">-->
    <div class="headline">
      <h1>Kontakt</h1>
    </div>
    <div id="message"></div>
      <form @submit.prevent="submit" method="post">
      <div class="display-flex">
        <div class="display-block">
          <ul>
            <li>
              <div class="margin-right">
                <label>Name</label>
              </div>
              <div class="verticalLineInput">
                <input name="name" v-model="name">
              </div>
            </li>
            <li>
              <div class="margin-right">
                <label>Email</label>
              </div>
              <div class="verticalLineInput">
                <input type="email" name="email" v-model="email">
              </div>
            </li>
            <li>
              <div class="margin-right">
                <label>Telefon</label>
              </div>
              <div class="verticalLineInput">
                <input type="tel" name="tel" v-model="tel">
              </div>
            </li>
            <li class="hide-mobile no-border-hover">
              <p id="contactDetails">
                Harald P. Bohner <br>
                Tieckstr. 6<br>
                04275 Leipzig<br>
                Tel.:<a href="tel:+491637313955"> 0163/7313955</a><br>
                E-Mail: <a href="mailto:info@gitarrenunterricht-bohner.de">info@gitarrenunterricht-bohner.de</a>
              </p>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li class="verticalLine">
              <label class="label-name">Nachricht</label>
              <div class="width-90">
                <textarea type="text" v-model="message" name="message"/>
              </div>
            </li>
            <li class="no-border-hover">
              <button type="submit" class="button-main">Senden</button>
              <p class="font-weight">*optional</p>
            </li>
            <li class="no-border-hover">
              <p class="font-weight">Durch absenden des Formulars stimmen Sie der <router-link to="/datenschutz" target="_blank">Datenschutzerklärung</router-link> zu.</p>
            </li>
            <li class="display-mobile no-border-hover">
              <p id="contactDetails">
                Harald P. Bohner <br>
                Tieckstr. 6<br>
                04275 Leipzig<br>
                Tel.:<a href="tel:+491637313955"> 0163/7313955</a><br>
                E-Mail: <a href="mailto:info@gitarrenunterricht-bohner.de">info@gitarrenunterricht-bohner.de</a>
              </p>
            </li>
          </ul>
        </div>
      </div>
        <input type="text" class="rC" v-model="empty">
      </form>
  </div>
</template>

<script>
import axios from "axios";
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: "KontaktPage",
  data(){
    return{
      v$: useValidate(),
      name: '',
      email: '',
      tel: '',
      message: '',
      empty: ''
    }
  },
  methods: {
    submit: function (){
      let messageBox = document.querySelector('#message');
      this.v$.$validate();
      if(this.empty === '' && !this.v$.$error) {
        axios.post(window.location.origin + '/contact.php', {
          customerName: this.name,
          mailAddress: this.email,
          phone: this.tel,
          text: this.message,
        }).then(function (response) {

          if (response.data === 'success') {
            //remove old message
            while (messageBox.firstChild) {
              messageBox.removeChild(messageBox.firstChild);
            }
            //insert new message
            messageBox.insertAdjacentHTML(
                'afterbegin',
                '<p style="background-color: rgb(150, 191, 150); padding: 8px; text-align: center; font-size: 15px; width: 100%;">Vielen Dank für Ihre Nachricht.</p>'
            );
          } else {
            //remove old message
            while (messageBox.firstChild) {
              messageBox.removeChild(messageBox.firstChild);
            }
            //insert new message
            messageBox.insertAdjacentHTML(
                'afterbegin',
                '<p style="background-color: rgb(215 118 120); padding: 8px; text-align: center; font-size: 15px; width: 100%;">Leider ist ein Problem beim Versenden aufgetretet. Bitte nutzen Sie die unten angegebene E-Mail Adresse. Wir bitten vielmals um Verzeihung.</p>'
            );
          }
        });
      }
      if(this.v$.$error){
        //remove old message
        while (messageBox.firstChild) {
          messageBox.removeChild(messageBox.firstChild);
        }
        //insert new message
        messageBox.insertAdjacentHTML(
            'afterbegin',
            '<p style="background-color: rgb(215 118 120); padding: 8px; text-align: center; font-size: 15px; width: 100%;">Bitte füllen Sie alle Pflichtfelder aus.</p>'
        );
      }
    }
  },
  validations() {
    return {
      name: { required },
      email: { required },
      message: { required },
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Kontakt';
      }
    },
  }
}
</script>

<style scoped>
.rC{
  display: none;
}

.content {
  max-width: 100vw;
}

.verticalLineInput:before {
  content: " | ";
  bottom: 0;
  left: 0;
  height: 50%;
  width: 2px;

  position: relative;
  color: #E98918;
}

.verticalLineInput {
  margin-left: 80px;
  width: 90% !important;
}

.verticalLine label:after{
  color: #E98918;
  font-weight: 400;
}


ul {
  list-style-type: none;
  list-style-position: outside;
  padding-left: 0;
}

ul li {
  margin-bottom: 30px;
  width: 90%;
  position: relative;
  border-bottom: 1px solid;
}

li:hover {
  background-color: rgba(150,150,150,0.1);
}

li.no-border-hover {
  border-bottom: 0;
}

.no-border-hover:hover {
  background-color: rgba(60,60,60,0);
}

.display-flex div {
  width: 50%;
}

input,
input:active,
textarea{
  width: 90%;
  height: 48px;
  border: none;
  background-color: inherit;
}

input {
  font-size: 18px;
}

textarea {
  padding-top: 2em;
  height: 15vw;
  font-size: 18px;

}

input:focus,
textarea:focus{
  outline: none;
}

label,
.label-name {
  position: absolute;
}

label {
  top: 20%;
  font-weight: 300;
}

.label-name {
  top: 2%;
}

button{
  width: 100%;
  color: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

#contactDetails {
  text-align: center;
  font-style: italic;
  font-weight: 300;
}

.font-weight {
  font-weight: 300;
  font-size: 16px;
}

a{
  color: black;
}

.width-90{
  width: 100%!important;
}

@media only screen and (max-width: 600px) {
  .display-flex {
    flex-direction: column;
  }

  ul li,
  input,
  input:active,
  textarea,
  .display-flex div {
    width: 100%;
  }

  textarea{
    height: 30vw;
  }

  input{
    width: 95%;
  }
}


</style>