<!--Maybe we need to do a code cleanup here. Just added new styles withon removing useless one. Sry but i dont have time :D -->
<template>
  <div class="content">
  <div class="headline">
    <h1>Über Mich</h1>
  </div>
    <div class="grid-container">
      <div class="item1">
        <p> Meine Mission ist, gemeinsam zu musizieren.</p>
        <p> Drei Brüder, die bereits als Kinder ihre erste Band gründen.</p>
        <p> Die Entscheidung, Gitarre & Arrangement an der Musikhochschule „Felix Mendelssohn Bartholdy“ in Leipzig zu studieren.</p>
        <p>Der Entschluss, in Leipzig zu bleiben und als Live- und Studiomusiker aktiv das Leben zu gestalten.
           Und schließlich nach vielen Jahren pädagogischer Tätigkeit meine eigene Musikschule für Gitarre zu gründen.</p>
        <p>Kribbelt es auch in deinen Fingerspitzen?</p>
        <p> Dann melde dich gleich jetzt zur kostenlosen Kennenlernstunde an.</p>
      </div>
      <div class="item2">
        <div>
          <img src="../assets/Uber_Mich_-_Foto_2.jpg" alt="Portrait-Bohner">
        </div>

        <div class="display-flex icons">
          <a href="https://instagram.com/couchkomplizen?igshid=YmMyMTA2M2Y=" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" style="height: 40px;"/></a>
          <a href="https://www.youtube.com/watch?v=MemuhjF2tL4" target="_blank"><font-awesome-icon icon="fa-brands fa-youtube" style="height: 40px;"/></a>
        </div>
      </div>
    </div>
    <div class="flex-button">
      <button class="button-main"><router-link to="/kontakt">Zur Kennlernstunde</router-link></button>
    </div>
</div>
</template>

<script>
export default {
  name: "UeberMich",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Über Mich';
      }
    },
  }
}

</script>

<style scoped>
a,
a:hover {
  color: black;
}

.icons a {
  border: 1px solid transparent;
}

.icons a {
  padding: 10px
}
.icons a:hover {
  padding: 20px;

}

.fa-ul{
}

.grid-container{
  display: grid;
  grid-template-columns: auto auto;
}

.icons {
  margin-top: 30px;
}

.icons div{
  text-align: center;
  font-size: 40px;
}

.item1,
.item2{
  padding-right: 5em;
}

.button-main{
  box-sizing: content-box;
  width: 30% !important;
  height:15% !important;
  padding: 0;
}

img{
  width: 14em;
  height: 14em;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.25) 0 5px 5px;
}

.display-flex {
  justify-content: space-around;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 880px) {
  .grid-container{
    display: flex;
    flex-direction: column-reverse;
  }

  .grid-mobile{
    display: grid;
    grid-template-columns: auto auto;
  }

  .item1{
    grid-area: 2/2;
    padding-right: 0;
    padding-top: 2em;
  }

  .item2{
    grid-area: 1/1;
    padding-right: 0;
  }

  .button-main{
    box-sizing: border-box;
  }

  .display-mobile{
    text-align: center;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  p{
    padding: 0;
    width: 100%
  }

  img{
    width: 22em;
    height: 22em;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media only screen and (max-width: 880px) {
  .button-main{
    width: 100% !important;
  }

  .flex-button {
    display: flex;
  }
}

</style>