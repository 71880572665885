<template>
  <MainHeader :key="$route.path"/>
  <div class="flex-wrapper">

    <router-view v-slot="{ Component }" >
      <transition name="slide-fade" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>

    <app-footer/>
  </div>
</template>
<script>
import MainHeader from "@/components/MainHeader";
import Footer from "@/components/Footer";

export default {
  name: "App",
  //baseUrl: 'localhost:8080',
  components: {
    MainHeader,
    // footer already html tag
    'app-footer': Footer,
  }
};
</script>

<style>
/*Note to our fonts: We use google fonts "Open Sans". Because of data security policy we need to include them locally.
Fonts are included in the Landpage.vue file. If I do it here it's not working. VueMagic... :D */

/* import fontawesome for icons (free icons)*/
/*@import url(https://kit.fontawesome.com/87154d83b1.js);*/
/*<script src="https://kit.fontawesome.com/87154d83b1.js" crossorigin="anonymous"></script>
@import "https://kit.fontawesome.com/87154d83b1.js";*/
/*In here our default styles we need on ever page */
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {background-color: rgba(198, 168, 136, 0.53)}

.content {
  padding: 50px 100px;
}

/*Position*/
.display-flex {
  display: flex;
}

.center {
  display: flex;
  justify-content: center;
}

/*Fonts*/
#app {
  font-family: 'Open Sans',serif;
  font-size: 20px;
  background-color: rgba(198, 168, 136, 0.53);
}

.headline {
  display: flex;
  justify-content: center;
  font-size: 25px;
  border-bottom: 1px solid;
  margin-bottom: 60px;
}

.headline h1 {
  margin-bottom: 15px;
}

/*<Transition name="slide-fade">*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
/*Shapes*/
.verticalLine label:after,
.verticalLine p:not(:last-child):after,
.verticalLine a:after{
  content: " | ";
  bottom: 0;
  left: 0;
  height: 50%;
  width: 2px;
  position: relative;
  top: -2px;
}

/*Buttons*/
.button-main {
  appearance: none;
  background-color: rgba(120,120,120,0.1);
  border: 1px solid #E98918;
  border-radius: 15px;
  box-sizing: border-box;
  color: #E98918;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: inherit;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 50%;
  will-change: transform;
}

.button-main:disabled {
  pointer-events: none;
}


.button-main:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  background-color: rgba(60,60,60,0.1);
}

.button-main:active {
  box-shadow: none;
  transform: translateY(0);
}

button {
  margin-top: 30px;
  margin-bottom: 30px;
}

button a {
  text-decoration: none;
  color: black;
}

.button-main:hover a{
  color: black;
}

/*Display Rules*/
.display-mobile {
  display: none;
}

.display-block {
  display: block !important;
}

/* wrapper to fil ful page even if content is not enough footer will be at the botoom */
.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}



/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  /*Fonts*/
  #app {
    font-size: 16px;
  }
  .headline {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .headline h1 {
    margin-bottom: 5px;
  }

  .display-mobile {
    display: block;
  }

  .hide-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 880px) {
  .content {
    padding: 50px 13px;
  }
}
</style>