<template>
  <div class="content">
    <div class="headline">
      <h1>Karte und Wegbeschreibung</h1>
    </div>
    <div class="display-flex">
<!--    <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2493.424129164932!2d12.362995315717232!3d51.321718332083435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6f9d467791497%3A0xcb1636552d82e2a2!2sTieckstra%C3%9Fe%206%2C%2004275%20Leipzig!5e0!3m2!1sde!2sde!4v1656340439468!5m2!1sde!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>-->
      <iframe class="map" width="600" height="450" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=12.363747060298921%2C51.32099927003848%2C12.366670668125154%2C51.32228159420868&amp;layer=mapnik" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <div>
      <h3>Adresse</h3>
      <p>Harald P. Bohner <br>
        Tieckstr. 6 <br>
        04275 Leipzig
      </p>
      <h3>Wegbeschreibung</h3>
        <div>
        Der Unterrichtsraum ist gut erreichbar in der Südvorstadt gelegen. <br> <br>
          <h3>Bus/ Tram</h3>
          <h5>Nur wenige Gehminuten entfernt von:</h5>
          <ul>
            <li>Bus 60 und 74 </li>
            <ul>Fockestraße </ul>
            <li>Bus 89 </li>
            <ul> A.-Bebel-/K.-Eisner-Str. </ul>
          </ul>
          <h5>Ca. 10 min Fußweg:</h5>
          <ul>
            <li> Tram 10 und 11 </li>
            <ul>Karl-Liebknecht-/ Kurt-Eisner-Str. </ul>
        </ul>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Anfahrt",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Anfahrt';
      }
    },
  }

}

</script>

<style scoped>

.map {
  width: 100%;
  height: 25em;
  margin-right: 50px;
}

iframe{
  box-shadow: rgba(0, 0, 0, 0.25) 8px 8px 5px;
}

@media only screen and (max-width: 600px) {
  .display-flex{
    display: block;
  }
  .map{
    padding-right: 0;
  }
}

</style>