import App from 'App.vue'
<template>
  <div class="mb-60 $enable-shadows">
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top shadow-lg" >
      <div class="container">
        <router-link to="/" class="navbar-brand image"> <img  class="safari_only" src="../assets/Logo_grey.png" alt="Logo-Bohner"> </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/unterricht">Unterricht</router-link>
            </li>
            <li class="nav-item safari_only">
              <router-link to="/uebermich">Über mich</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/kontakt">Kontakt</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/anfahrt"> Anfahrt</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import { getCurrentInstance } from "vue";

export default {
  name: "MainHeader",
  data(){
    return{
      baseUrl: getCurrentInstance().appContext.config.globalProperties['baseURL']
    }
  }
}
</script>

<style scoped>

nav {
  background-color: #303030;
  padding-top: 3px;
  padding-bottom: 3px;
}

nav a{
  color: white;
  text-decoration: none;

}

nav img{
  height: 3em;
  width: 110%;
  object-fit: cover;
  position: relative;
  left: 0;
  float: left;
}

nav li{
  font-size: 18px;
  margin-right: 25px;
  text-align: right;
}

.router-link-active{
  border-bottom: 2px solid #E98918;
}

a.router-link-active.image {
  border-bottom: 0 !important;
}

.navbar .navbar-nav .nav-link:hover {
  color: #E98918;
}

.navbar .navbar-nav .nav-item {
  position: relative;
}

.navbar .navbar-nav .nav-item::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #E98918;
  width: 0;
  content: "";
  height: 2px;
}

.navbar .navbar-nav .nav-item:hover::after {
  width: 100%;
}

/*Navbar has on every screen height of 70px*/
.mb-60 {
  margin-bottom: 60px;
}

button {
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .navbar .navbar-nav .nav-item:hover::after {
    width: 0;
  }
}

/*Fck! Safari Shit*/
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  .navbar-brand {
    width: 9%;
  }

  nav img {
    height: 3.5em;
  }

  @media only screen and (max-width: 920px) {

    .navbar-brand {
      width: 14%;
    }

    nav img {
      height: 3em;
    }
  }

  @media only screen and (max-width: 600px)
  {

    nav img {
      height: 2.5em;
      width: 150%;
    }
  }}
}




</style>