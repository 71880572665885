import { createApp } from 'vue'
import  App  from './App.vue'
import router from "@/router";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import axios from 'axios'
import VueAxios from 'vue-axios'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(faInstagram, faYoutube)
//import VueGoogleMaps from '@fawmi/vue-google-maps' // source: https://www.youtube.com/watch?v=m4ad3eEFhAo


// 1. Assign app to a variable
let app = createApp(App)

// 2. Assign the global variable before mounting
app.config.globalProperties.baseURL = 'localhost:8080';
// 3. Use router and mount app
app.use(VueAxios, axios)
createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
