import {createRouter, createWebHistory} from 'vue-router';
import LandingPage from "@/components/LandingPage";
import DatenSchutz from "@/components/DatenSchutz";
import MyLesson from "@/components/MyLesson";
import ContactPage from "@/components/ContactPage";
import Impressum from "@/components/Impressum";
import Anfahrt from "@/components/Anfahrt";
import UeberMich from "@/components/UeberMich";

// create new Route by adding new Object into the Array
const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage,
        meta: {
            publicRoute: true,
            title: 'Home',
            metaTags: [
                {
                    name: 'Philosophie',
                    content: 'Ich verstehe mich als Lernbegleiter, Motivator und Mitmusiker. Der Unterricht ist für Schüler jeden Alters geeignet. Die Inhalte werden individuell auf jeden Schüler abgestimmt.'
                },
            ]
        }
    },
    {
        path: '/unterricht',
        name: 'Unterricht',
        component: MyLesson,
        meta: {
            publicRoute: true,
            title: 'Unterricht und Preise',
            metaTags: [
                {
                    name: 'Unterricht/Preise',
                    content: 'Instrumente: E-Gitarre, Western Gitarre, Klassische Gitarre, Ukulele.   <div class="price-list single-prices">\n' +
                        '        <h4>Einzelunterricht</h4>\n' +
                        '      <table>\n' +
                        '        <tr>\n' +
                        '          <td>30 min je Woche</td>\n' +
                        '          <td class="align-right">77.- € Monatsbeitrag</td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td style="visibility: hidden"> space </td>\n' +
                        '          <td></td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td>45 min je Woche</td>\n' +
                        '          <td class="align-right">115.- € Monatsbeitrag</td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td style="visibility: hidden"> space </td>\n' +
                        '          <td></td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td>60 min je Woche</td>\n' +
                        '          <td class="align-right">150.- € Monatsbeitrag</td>\n' +
                        '        </tr>\n' +
                        '      </table>\n' +
                        '      </div>\n' +
                        '      <div class="price-list">\n' +
                        '        <h4>Gruppenunterricht</h4>\n' +
                        '      <table>\n' +
                        '        <tr>\n' +
                        '          <td>2er Gruppe 45 min je Woche</td>\n' +
                        '          <td class="align-right">60.- € Monatsbeitrag</td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td style="visibility: hidden"> space </td>\n' +
                        '          <td></td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td>10er Karte - 45 min</td>\n' +
                        '          <td class="align-right">400.- € einmalig</td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td></td>\n' +
                        '          <td class="align-right">(oder 33.- mtl. Rate)</td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td>20er Karte - 45 min</td>\n' +
                        '          <td class="align-right">792.- € einmalig</td>\n' +
                        '        </tr>\n' +
                        '        <tr>\n' +
                        '          <td></td>\n' +
                        '          <td class="align-right">(oder 66.- mtl. Rate)</td>\n' +
                        '        </tr>\n' +
                        '      </table>'
                },
            ]
        }
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: ContactPage,
        meta: {
            publicRoute: true,
            title: 'Kontakt',
            metaTags: [
                {
                    name: 'Kontakt',
                    content: 'Kontaktieren Sie uns unter: Harald P. Bohner. Tieckstr. 6 04275 Leipzig Tel.: 0163/7313955 E-Mail: info@gitarrenunterricht-bohner.de'
                },
            ]
        }
    },
    {
        path: '/datenschutz',
        name: DatenSchutz,
        component: DatenSchutz
    },
    {
        path: '/impressum',
        name: Impressum,
        component: Impressum
    },
    {
        path: '/anfahrt',
        name: Anfahrt,
        component: Anfahrt,
        meta: {
            publicRoute: true,
            title: 'Anfahrt',
            metaTags: [
                {
                    name: 'Anfahrt',
                    content: 'Der Unterrichtsraum ist gut erreichbar in der Südvorstadt gelegen. Adresse Harald P. Bohner Tieckstr. 6 04275 Leipzig'
                },
            ]
        }
    },
    {
        path: '/ueberMich',
        name: UeberMich,
        component: UeberMich,
        meta: {
            publicRoute: true,
            title: 'Ueber mich',
            metaTags: [
                {
                    name: 'Ueber mich',
                    content: 'Meine Mission ist, gemeinsam zu musizieren. Drei Brüder, die bereits als Kinder ihre erste Band gründen.Die Entscheidung, Gitarre & Arrangement an der Musikhochschule „Felix Mendelssohn Bartholdy“ in Leipzig zu studieren. Kribbelt es auch in deinen Fingerspitzen? Dann melde dich gleich jetzt zur kostenlosen Kennenlernstunde an.'
                },
            ]
        }
    },
]

const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    }
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router