<template>
  <footer>
    <ul>
      <li class="verticalLine">
        <router-link to="/kontakt">Kontakt </router-link>
      </li>

      <li class="verticalLine">
        <router-link to="/impressum">Impressum </router-link>
      </li>

      <li>
        <router-link to="/datenschutz">Datenschutz </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  components: {}

}
</script>

<style scoped>

footer {
  background-color: rgba(217, 217, 217, 1);
  padding: 10px;
  font-weight: 100;
}

ul {
  text-align: center;
}

ul li{
  display: inline;
}

ul li a{
  color: black;
  text-decoration: none;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {

}

</style>