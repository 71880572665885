<template>
  <div id="landingPage">
    <div id="opener">
      <div id="buzzwords" class="verticalLine">
        <p>Lernbegleiter </p>
        <p>Motivator</p>&nbsp;
        <p>Mitmusiker</p>
      </div>
      <button class="button-main" id="scrollButton" @click="scrollFunction()">Philosophie</button>
    </div>
    <div class="content">
      <div class="relative" id="philosophie">
        <div class="headline" id="scrollspyHeading1">
          <h1>Philosophie</h1>
        </div>
        <div class="display-flex">
          <p>
            Ich verstehe mich als Lernbegleiter, Motivator und Mitmusiker.
            Eine positive, aufgeschlossene Lernumgebung ist mir wichtig. <br> <br>
            Meine Antrieb ist meine Liebe und Leidenschaft zur Musik zu teilen und weiterzugeben. <br> <br>
            Das Erlernen eines Instruments soll mit Freude geschehen, ohne dabei die technischen und theoretischen Aspekte außen vor zu lassen.
          </p>
          <div class="portrait-container">
            <img title="portait-hary" src="../assets/hary_portrait.jpeg">
          </div>
        </div>
        <div class="center">
          <p>
            Der Unterricht ist für Schüler jeden Alters geeignet. Die Inhalte werden individuell auf jeden Schüler abgestimmt.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import App from "@/App";
import { getCurrentInstance } from "vue";


export default {
  name: "LandingPage",
  data(){
    return {
      //baseUrl: App.baseUrl
      baseUrl: getCurrentInstance().appContext.config.globalProperties['baseURL']
    }
  },
  methods: {
    scrollFunction(){
      let philosophie = document.querySelector("#philosophie")
      philosophie.scrollIntoView({behavior: "smooth"})
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Home';
      }
    },
  }
};

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
  url("../assets/Open_Sans/static/OpenSans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
  url("../assets/Open_Sans/static/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

#opener{
  background-image: linear-gradient(rgba(120,120,120,0.2),rgba(120,120,120,0.2)), url("/src/assets/LandingPage_neu.jpeg");
  height: 50vw;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 25px;
  margin-bottom: 30px;
}

#buzzwords {
  display: flex;
  position: absolute;
  bottom: 55%;
  font-weight: 300;
  font-size: 36px;
}

.content p {
  font-weight: 300;
}

.display-flex {
  justify-content: center;
}

.display-flex p:first-child {
  width: 50%;
  padding-right: 20px;
}

.display-flex p:first-child {
  display: flex;
  align-items: center;
}

.center p{
  margin-top: 40px;
  width: 60%;
  text-align: center;
  margin-bottom: 80px;
  font-style: italic;
}

button {
  position: absolute;
  bottom: 45%;
  width: 22%;
  color: white;
  background-color: rgba(120,120,120,0.2);
}

.portrait-container {
  object-fit: cover;
}

img[title=portait-hary]{
  height: 30rem;
}


@media only screen and (max-width: 920px) {
  button{
    bottom: 35%;
  }

  .button-main {
    padding: 5px 16px;
    width: 30%;
  }

  .portrait-container {
    width: 50%;
  }

  img[title=portait-hary] {
    height: 23rem;
  }
}

@media only screen and (max-width: 600px) {

  #opener{
    margin-bottom: 0;
  }

  #buzzwords{
    font-size: 20px;
    bottom: 45%;
  }

  button {
    display: none;
  }

  .display-flex,
  .display-flex p:first-child {
    display: block;
    width: 100%;
    border-right: 0;
    padding: 0;
  }

  .portrait-container {
    width: 100%;
  }

  img[title=portait-hary] {
    height: 20rem;
    width: 100%;
    margin-bottom: 16px;
  }

  .center p{
    width: 100%;
    margin: 0;
  }
}
</style>