<!--Please do here a code cleanup aswell. Again no time for nothing.. :) -->
<template>
  <div class="content">
    <div class="headline">
      <h1>Instrumente</h1>
    </div>
    <div class="display-flex bubbles">
      <button v-on:click="slideToImg(0)" id="e" class="active-bubble-orange"><p>E-Gitarre</p></button> <!-- class: carousel__slide--active  -->
      <button v-on:click="slideToImg(1)" id="western"><p>Western Gitarre</p></button>
      <button v-on:click="slideToImg(2)" id="klassische"><p>Klassische Gitarre</p></button>
      <button v-on:click="slideToImg(3)" id="ukulele"><p>Ukulele</p></button>
    </div>

    <carousel v-on:click="eventNextButton()" ref="carousel" :transition="600" :items-to-show="1.5"  :wrap-around="true" auto-sh > <!--:autoplay="7000" :pauseAutoplayOnHover-->
      <slide v-for="slide in slides" :key="slide.id">
        <img :id="slide.id" class="slideImg" :src="slide.url" />
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>

    <div class="headline">
      <h1>Preisliste</h1>
    </div>

    <div class="center">
      <div class="price-list single-prices">
        <h4>Einzelunterricht</h4>
      <table>
        <tr>
          <td>30 min je Woche</td>
          <td class="align-right">68.- € Monatsbeitrag</td>
        </tr>
        <tr>
          <td style="visibility: hidden"> space </td>
          <td></td>
        </tr>
        <tr>
          <td>45 min je Woche</td>
          <td class="align-right">97.- € Monatsbeitrag</td>
        </tr>
        <tr>
          <td style="visibility: hidden"> space </td>
          <td></td>
        </tr>
        <tr>
          <td>60 min je Woche</td>
          <td class="align-right">120.- € Monatsbeitrag</td>
        </tr>
      </table>
      </div>
      <div class="price-list">
        <h4>Gruppenunterricht</h4>
      <table>
        <tr>
          <td>2 Personen 60 min je Woche</td>
          <td class="align-right width-50 ">65.- € p.P. Monatsbeitrag</td>
        </tr>
        <tr>
          <td style="visibility: hidden"> space </td>
          <td></td>
        </tr>
        <tr>
          <td>10er Karte - 45 min</td>
          <td class="align-right width-50 ">350.- € einmalig</td>
        </tr>
        <tr>
          <td></td>
          <td class="align-right width-50 ">(oder 30.- mtl. Rate)</td>
        </tr>
        <tr>
          <td>20er Karte - 45 min</td>
          <td class="align-right width-50">690.- € einmalig</td>
        </tr>
        <tr>
          <td></td>
          <td class="align-right width-50 ">(oder 58.- mtl. Rate)</td>
        </tr>
      </table>
      </div>
    </div>
    <p>Alle Gebühren sind als 1/12 der Jahresgebühr zu verstehen und pauschal jeden Monat, unabhängig von Ferien und Feiertagen, zu bezahlen.
      An gesetzlichen Feiertagen und in den Ferien der allgemein bildenden Schulen findet kein Unterricht statt.
      Der Einstieg ist jederzeit möglich. Der Vertrag ist jeweils zum Monatsende mit 2 Wochen Frist  kündbar.<br><br>
      <strong>Eine Kennenlernstunde ist kostenlos und unverbindlich.</strong>
    </p>
    <div class="center flex-button">
      <button class="button-main"><router-link to="/kontakt">Zur Kennenlernstunde</router-link></button>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import Images from "@/images";
import { ref } from 'vue'
//import useValidate from "@vuelidate/core/dist/index.esm";

export default {
  name: "MyLesson",
  components: {
    Carousel,
    Slide,
    Navigation
  },
  data() {
    return {
      slides: [{id: 1,url: Images.image_e_gitarre}, {id: 2,url: Images.image_western_gitarre}, {id: 3,url: Images.image_klassische_gitarre}, {id: 4,url: Images.image_ukulele}]
    }
  },
  setup(){
    const carousel = ref(null);

    const slideToImg = (id) => {
      carousel.value.slideTo(id);
      setBubbleColor(id)
    }

    const eventNextButton = () => {
      let active = document.querySelector(".carousel__slide--active").firstChild
      let id = active.id
      setBubbleColor(parseInt(id)-1)
    }

    const setBubbleColor = (id) => {
      /* reason: id=1, 2.. exists already  (img  slide loop) */
      let guitarId
      switch (id){
        case 0:
          guitarId = "e"
              break
        case 1:
          guitarId = "western"
              break
        case 2:
          guitarId = "klassische"
              break
        case 3:
          guitarId = "ukulele"
      }
      let active = document.querySelector(".active-bubble-orange")
      active.classList.remove('active-bubble-orange')
      let button = document.getElementById(guitarId)
      button.classList.add("active-bubble-orange")
    }

    return{
      carousel,
      slideToImg,
      setBubbleColor,
      eventNextButton
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Unterricht und Preise';
      }
    },
  }
}
</script>


<style scoped>

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.align-right{
  text-align: right !important;
}

.bubbles {
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.bubbles button {
  border: 1px solid;
  box-shadow: rgba(0, 0, 0, 0.25) 0 5px 5px;
  border-radius: 100%;
  background: rgba(233, 137, 24, 0.14);
  color: #E98918;
  height: 150px;
  width: 150px;
  position: relative;
  justify-content: center;
}

button.active-bubble-orange {
  background-color: #E98918;
}

.bubbles button p {
  color: white;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 70%);
  width: 98%;
  top: 2%;
}

.bubbles button:first-child p,
.bubbles button:last-child p {
  top: 25%;
}

.headline{
  margin-bottom: 20px;
}

.slideImg{
  height: 27rem;
  width: 95%
}

table {
  border-collapse: collapse;
  border-spacing:0 15px;
  align-self: center;
}

.center{
  justify-content: space-around;
}

h4{
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 20px;
}

.price-list {
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  vertical-align: center;
}

.single-prices{
  padding: 16px;
  margin-right: 10px;
}

.width-50 {
  width: 50%;
}

tr td:first-child{
  text-align: right;
  border-right: 1px solid black;
  padding-right: 30px;
}

tr td:last-child{
  text-align: left;
  padding-left: 30px;
  overflow: hidden;
}

tr td:only-child{
  text-align: right;
  font-weight: 600;
  border-spacing:0 15px;
}

.hide-mobile {
  width: 90%;
}

@media only screen and (max-width: 880px) {
  .center{
    flex-direction: column;
  }

  .price-list{
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .slideImg{
    height: 15rem;
    width: 100%
  }

  table {
    margin-bottom: 30px;
    display: block;
  }

  .center.flex-button {
    display: flex;
  }

  .display-flex{
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    padding: 5px;
  }

  .bubbles button {
    border-radius: 0;
    height: 50px;
  }

  .bubbles {
    margin-bottom: 30px;
  }

  .bubbles button p{
    transform: translate(-50%, 70%);
    bottom: 50%;
    width: 70%;
    font-size: 15px;
    top: -30%;
  }

  .display-mobile .center {
    display: block;
  }

  .headline-h3{
    background-color: transparent;
    font-size: 18px;
  }

  .button-main {
    width: 100%;
  }

}

.button-main{
  margin-top: 60px;
}
</style>